/**
* Bootstrap : Switch
*/

.form-switch {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    label {
        margin: {
            right: 10px;
        }

        &.smaller {
            padding: {
                top: 5px;
            }
        }
    }

    input {
        cursor: pointer;
    }
    
    .form-check-input {
        float: none;
        height: 21px;
        background-color: var(--color-border);
        border-color:  var(--color-border);

        &:checked {
            background-color: var(--color-success);
            border-color:  var(--color-success);
        }
    }
    
    form-field-error {
        flex: 0 0 100%;
    }

    &.label-right {
        label {
            order: 2;
            margin: {
                left: 15px;
            }
        }

        input {
            order: 1;
        }
    }
}