/**
* Abstracts : Extends
*/

%btn {
  display: flex;
  align-items: center;
  min-height: 36px;
  margin: 0;
  padding: 10px 40px;
  border-radius: var(--border-radius);
  font-size: 1rem !important;
  color: #fff;
  transition: all var(--speed-default) ease;

  @include media-breakpoint-up(md) {
    font-size: 1.1rem !important;
  }
}

%disabled {
  &:disabled {
    color: #fff !important;
    background: var(--color-disabled) !important;
    border: var(--color-disabled) !important;
    pointer-events: none !important;

    &:focus,
    &:hover,
    &:active {
      cursor: not-allowed !important;
    }
  }
}

%btnFormGradient {
  background: linear-gradient(360deg, #EFEFEF -5.32%, rgba(239, 239, 239, 0) 39.36%);

  &:focus,
  &:hover,
  &:active {
    background: var(--color-primary-dark);

    .icon {
      &:before,
      &:after {
        background-color: #fff;
      }
    }

    svg {
      path: {
        fill: #fff;
      }
    }
  }
}
