/**
* Forms : Input Password
*/

// Password Wrapper
.input-password-wrapper {
  position: relative;
  padding: {
    right: 49px;
  }

  input {
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .btn-eye {
    @extend .btn;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    width: 50px;
    border: 1px solid var(--color-border);
    border-radius: 0 3px 3px 0 !important;
    @extend %btnFormGradient;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:focus,
    &:hover,
    &:active {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
