/**
* Forms : NG Select
*/

/* Importing NG Select SCSS file. */
@import "@ng-select/ng-select/themes/default.theme.css";

.ng {
  &-select {
    &-container {
      min-height: 50px !important;
    }
  }

  // Arrow
  &-arrow {
    border-color: var(--color-text) transparent transparent !important;
  }

  // Clear
  &-clear-wrapper {
    color: var(--color-text);
  }

  // Select Open
  &-select-opened {
    .ng {
      &-arrow {
        border-color: transparent transparent var(--color-text) !important;
      }
    }
  }
}

// inherit .form-control:is-valid .form-control:is-invalid for ng-select
ng-select {
  .is-valid {
    border-color: var(--color-success);
  }

  .is-invalid {
    @extend .is-invalid;
    border-color: var(--color-danger);
  }
}
