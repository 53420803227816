/* You can add global styles to this file, and also import other style files */

/* Import Theme Variables */
@import "theme/styles/abstracts/variables";

/* Import bootstrap */
@import 'bootstrap/scss/bootstrap';

/* Important Theme Styles */
@import "theme/styles/init";

@import '@angular/cdk/overlay-prebuilt.css';