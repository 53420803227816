/**
* Forms : Input Radio
*/

.form-radio {
  .form-check {
    position: relative;
    display: flex;
    margin: {
      top: 5px;
    }

    &-label {
      order: 2;
      margin: {
        left: 10px;
      }
      cursor: pointer;
    }

    &-input {
      order: 1;
    }

    cursor: pointer;
  }

  &.horizontal {
    fieldset {
      display: flex;
      flex-wrap: wrap;
    }

    legend {
      width: 100%;
      flex: 0 0 100%;
    }

    .form-check {
      + .form-check {
        margin: {
          left: 25px;
        }
      }
    }
  }

  input {
    &.ng-invalid,
    &.is-invalid {
      padding: {
        bottom: 15px;
      }
    }
  }

  form-field-error {
    flex: 0 0 100%;
  }
}
