/**
* Elements : Buttons
*/

button {
  padding: 0;
  background: transparent;
  border: 0;

  &:before,
  &:after {
    transition: all var(--speed-default) ease;
  }

  &:focus,
  &:hover,
  &:active {
    box-shadow: none !important
  }

  fa-icon {
    display: block;
  }

  svg.svg-inline--fa {
    display: block;
    max-width: 18px;
    max-height: 18px;

    path {
      transition: all var(--speed-default) ease;
    }
  }

  // Button Default
  &.btn-default {
    &:focus,
    &:hover,
    &:active {
      svg {
        path {
          fill: var(--color-primary);
        }
      }
    }

    @extend %disabled;
  }

  // Common Primary and Secondary
  &.btn-primary,
  &.btn-secondary {
    svg {
      margin: {
        right: 10px;
      }
    }
  }

  // Button Primary
  &.btn-primary {
    @extend %btn;
    color: #fff !important;
    background: var(--color-primary);
    border: 1px solid var(--color-primary);

    svg {
      path {
        fill: #fff;
      }
    }

    &:focus,
    &:hover,
    &:active {
      background: var(--color-primary-dark);
      border-color: var(--color-primary-dark);

      svg {
        path {
          fill: #fff;
        }
      }
    }

    @extend %disabled;
  }

  // Button Secondary
  &.btn-secondary {
    @extend %btn;
    color: var(--color-primary);
    background: var(--color-secondary);
    border: 1px solid #8AC2F4;

    svg {
      path {
        fill: var(--color-primary);
      }
    }

    &:focus,
    &:hover,
    &:active {
      color: #fff;
      background: var(--color-primary-dark);
      border-color: var(--color-primary-dark);

      svg {
        path {
          fill: #fff;
        }
      }
    }

    @extend %disabled;
  }

  // Button Cancel
  &.btn-cancel {
    @extend %btn;
    color: #fff !important;
    background: var(--color-cancel);

    &:focus,
    &:hover,
    &:active {
      background: var(--color-cancel-dark);
    }

    @extend %disabled;
  }

  // Button Delete
  &.btn-danger {
    @extend %btn;
    color: #fff !important;
    background: var(--color-danger);

    &:focus,
    &:hover,
    &:active {
      background: var(--color-danger-dark);
    }

    @extend %disabled;
  }

  // Button Add
  &.btn-add {
    width: 30px;
    height: 30px;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 3px;
      background: #000;
      border-radius: 3px;
    }

    &:before {
      transform: translate(-50%, -50%);
    }

    &:after {
      transform: translate(-50%, -40%) rotate(90deg);
    }

    &:focus,
    &:hover,
    &:active {
      &:before,
      &:after {
        background: var(--color-primary) !important;
      }
    }
  }

  // Button Edit
  &.btn-edit {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: var(--button-square-size);
    height: var(--button-square-size);

    svg.svg-inline--fa {
      width: 22px;
      height: 22px;
      margin: 0 0 auto;

      path {
        transition: all var(--speed-default) ease;
      }
    }

    &:focus,
    &:hover,
    &:active {
      svg {
        path {
          fill: var(--color-primary);
        }
      }
    }
  }
}
