/**
* Elements : HTML - Body
*/

html,
body {
  min-height: 100vh;
}

html {
  font-weight: normal;
  line-height: 1.2em;
  background: var(--color-background);
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  font-family: var(--font-primary);
  background: var(--color-background);
}
