/**
* Forms : Input Checkbox
*/

.form-checkbox {
  .form-check {
    display: flex;
    align-items: center;
    margin: {
      top: 5px;
    }

    + .form-check {
      margin: {
        top: 15px;
      }
    }

    &-input {
      order: 1;
      width: 25px;
      height: 25px;
      margin: {
        top: 0;
      }
      cursor: pointer;
    }

    &-label {
      order: 2;
      margin: {
        left: 10px;
      }
      cursor: pointer;
    }
  }

  &.horizontal {
    fieldset {
      display: flex;
      flex-wrap: wrap;
    }

    legend {
      width: 100%;
      flex: 0 0 100%;
    }

    .form-check {
      + .form-check {
        margin: {
          top: 0;
          left: 25px;
        }
      }
    }
  }
}
