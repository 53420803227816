/**
* Forms : Common
*/

input.form-control {
  padding: var(--padding-input) 35px var(--padding-input) var(--padding-input) !important;
}

sup.required {
  top: 0;
  font-size: 1rem;
  color: var(--color-danger);
}

.invalid-feedback {
  display: block;
}

fieldset {
  border: 0;
  padding: 0;
}

label, legend {
  font-size: 1rem;
}

label {
  &.smaller {
    font-size: 0.8rem;
    color: var(--color-text-pale);
  }
}
