/**
* Components : Toastr
*/

// Mix-ins
@mixin borderRadius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin boxShadow($boxShadow) {
  -moz-box-shadow: $boxShadow;
  -webkit-box-shadow: $boxShadow;
  box-shadow: $boxShadow;
}

.toast,
.ngx-toastr {
  border-color: var(--color-white);
  transition: all 0.3s ease;
}

// Styles
.toast {
  // Positions
  &-top-center {
    top: 0;
    right: 0;
    width: 100%;
  }

  &-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
  }

  &-top-left {
    top: 12px;
    left: 12px;
  }

  &-top-right {
    top: 12px;
    right: 12px;
  }

  &-bottom-right {
    right: 12px;
    bottom: 12px;
  }

  &-bottom-left {
    bottom: 12px;
    left: 12px;
  }
}

#toast-container {
  position: fixed;
  z-index: 999999;

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .toast {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    @include borderRadius(3px 3px 3px 3px);
    background-position: 15px center;
    background-repeat: no-repeat;
    @include boxShadow(0 0 12px var(--color-text-pale));
    color: var(--color-white);
    opacity: 0.9;

    &:focus,
    &:hover,
    &:active {
      @include boxShadow(0 0 12px var(--color-black));
      opacity: 1;
      cursor: pointer;
    }

    &-title {
      font-weight: bold;
    }

    &-body {
      padding: 10px 10px 20px;
    }

    &-message {
      word-wrap: break-word;

      a,
      label {
        color: var(--color-white);
      }

      a {
        &:hover,
        &:focus,
        &:hover {
          color: var(--color-primary);
          text-decoration: none;
        }
      }
    }

    &-header {
      padding: 0;
      background: transparent;

      strong {
        color: var(--color-white);
        background: transparent;
        border: 0;
      }

      .btn-close {
        margin: {
          left: auto;
          right: 0;
        }
        opacity: 1;
      }
    }

    &.toast-info {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
    }

    &.toast-error {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
    }

    &.toast-success {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
    }

    &.toast-warning {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
    }
  }
}

.toast {
  background-color: var(--color-black);
}

.toast-success {
  background-color: var(--color-success);
}

.toast-error {
  background-color: var(--color-danger);
}

.toast-info {
  background-color: var(--color-primary-dark);
}

.toast-warning {
  background-color: var(--color-warning);
}

progress-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: var(--color-black);
  opacity: 0.4;
}

/*Animations*/
div[toast] {
  opacity: 1 !important;

  &.ng-enter {
    opacity: 0 !important;
    transition: opacity 0.3s linear;
  }

  &.ng-enter.ng-enter-active {
    opacity: 1 !important;
  }

  &.ng-leave {
    opacity: 1;
    transition: opacity 0.3s linear;
  }

  &.ng-leave.ng-leave-active {
    opacity: 0 !important;
  }
}

/*Responsive Design*/

@media all and (max-width: 240px) {
  #toast-container {
    .toast.div {
      padding: 8px 8px 8px 50px;
      width: 11em;
    }

    & .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container {
    .toast.div {
      padding: 8px 8px 8px 50px;
      width: 18em;
    }

    & .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container {
    .toast.div {
      padding: 15px 15px 15px 50px;
      width: 25em;
    }
  }
}
