/**
* Forms : Textarea
*/

textarea {
  width: 100%;
  height: 160px;
  padding: 15px;
  border: 1px solid var(--color-border);
  border-radius: 5px;
}
