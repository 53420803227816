/**
* Elements : DL
*/

dl {
  margin: 0;

  dt, dd {
    margin: 0;
  }

  dt {
    font-weight: 400;
  }

  dd {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  .dl-group {
    position: relative;

    + .dl-group {
      margin: {
        top: 15px;
      }
      padding: {
        top: 15px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(var(--bs-gutter-x) * 0.5);
        right: calc(var(--bs-gutter-x) * 0.5);
        height: 1px;
        background-color: var(--color-border)
      }
    }

    &.no-border {
      &:before {
        display: none;
      }
    }
  }
}
