/**
* Components : AG Grid
*/

/* Importing AG Grid SCSS file. */
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-balham.css";
//@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

$ag-default-row-height: 42px;

.ag-theme-alpine {
  width: 100%;
  min-height: 400px;
}

.ag-theme-balham {
  width: 100%;
  min-height: 400px;

  .ag {
    &-root-wrapper {
      border: 0;
    }

    &-root {
      overflow: visible;
    }

    // Header
    &-header {
      background: transparent;
      border: 0;
      border-top: 1px solid #EFEFEF;

      // Header Row
      &-row {
        height: $ag-default-row-height;
      }

      // Header Cell
      &-cell {
        padding: 6px;

        &:first-child {
          padding: {
            left: 25px;
          }
        }

        &-label {
          color: var(--color-text-pale);
          font-size: 0.78rem;
          letter-spacing: 0.05em;
          text-transform: uppercase;
        }

        // Sorted ASC
        &-sorted-asc {

        }

        // Sorted DESC
        &-sorted-desc {

        }
      }
    }

    // Sorting
    &-sort {
      // ASC
      &-ascending-icon {

      }

      // DESC
      &-ascending-icon {

      }
    }

    // Body Row
    &-row {
      height: $ag-default-row-height;
      border-top: 1px solid #EFEFEF;

      &:focus,
      &:hover {
        background: var(--color-grid-hover-background);
      }

      &.user-disabled,
      &.user-deleted {
        background: rgba(196, 196, 196, 0.15);
      }
    }

    // Body cell
    &-cell {
      padding: 6px;
      font-size: 0.95rem;

      &:first-child {
        padding: {
          left: 25px;
        }
      }

      ngx-avatars {
        + .ag-grid-cell-value {
          @include media-breakpoint-up(lg) {
            margin: {
              left: 40px;
            }
          }

          @include media-breakpoint-up(xl) {
            margin: {
              left: 50px;
            }
          }
        }
      }

      img {
        border-radius: 3px;
      }
    }
  }
}

// AG Grid Overflow Overwrite
.ag {
  &-root {
    overflow: visible;
  }

  &-row {
    z-index: 0;

    &.ag-row-focus {
      z-index: 1;
    }
  }

  &-cell {
    &.actions-cell {
      overflow: visible;

      .ag-cell-wrapper,
      .ag-cell-value {
        overflow: visible;
      }
    }
  }

  // Make AG Grid height 100% FIX
  &-root-wrapper-body {
    &.ag-layout-normal {
      &.ag-focus-managed {
        height: 100%;
      }
    }
  }
  
  &-dnd-ghost {
    width: auto;
    min-height: 0;
  }
}