/**
* Typography : Copy
*/

small {
  color: var(--color-text-pale);
  font-weight: 400;
}

.lowercase {
  text-transform: lowercase;
}
