/**
* Forms : Color Picker
*/

.colorpicker {
  label {
    margin: {
      right: 15px;
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }

  input[type="color"] {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    height: 150%;
    -webkit-appearance: none;
    border: none;
    transform: translate(-50%, -50%);
  }
}
