/**
* Components : Alert
*/

.alert {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: var(--border-radius);

    h2,h3,h4,h5, p {
        color: var(--color-text);
    }

    h2,h3,h4,h5 {
        font-size: 1.3rem;
        font-weight: 700;
    }

    p {
        margin: 0;
        font-size: 0.95rem;

        + p {
            margin: {
                top: 10px;
            }
        }
    }

    svg {
        margin: {
            right: 15px;
        }
    }

    // Alert Danger
    &.alert-danger {
        background: var(--color-danger-background);
        border-color: var(--color-error-border);

        svg {
            path {
                fill: rgb(228, 49, 49);
            }
        }
    }

    &.alert-section {
        margin: {
            top:25px;
            bottom: 25px;
        }
        @include media-breakpoint-up(md) {
            margin: {
                bottom: 35px;
            }
            padding: 30px 20px;
            border-radius: 15px;
        }

        @include media-breakpoint-up(lg) {
            margin: {
                bottom: 45px;
            }
            padding: 40px 30px;
            border-radius: 20px;
        }
    }

    &.icon-danger {
        svg {
            path {
                fill: rgb(228, 49, 49);
            }
        }
    }

    // Alert Actions
    &-actions {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
        
        @include media-breakpoint-up(md) {
            margin: {
                left: auto;
            }
        }

        &__item {
            align-self: center;
            
            + .alert-actions__item {
                margin: {
                    left: 10px;
                }
            }
        }
    }
}