/**
* Elements : Media
*/

img {
  height: auto;
}

svg {
  path {
    @include cssanim;
  }
}
