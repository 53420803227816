/**
* Components : Card
*/

.card {
  flex: 1;

  dms-section-title {
    margin: 0;
  }

  .section-title {
    padding: 0 !important;
  }
}
