/**
* Abstracts : Variables
*/

:root {
  // ------------------------------------------------------------
  // Fonts
  // ------------------------------------------------------------
  --font-primary: 'Poppins', Arial, Sans-Serif;

  // ------------------------------------------------------------
  // Colors
  // ------------------------------------------------------------
  --color-primary: #1AA8F8;
  --color-primary-dark: #1A3F62;

  --color-secondary: #E9F6FF;

  --color-success: #24B300;

  --color-danger: #E43131;
  --color-danger-dark: #c62727;

  --color-warning: orange;

  --color-cancel: #666;
  --color-cancel-dark: rgb(112, 112, 112);

  --color-disabled: rgb(66, 66, 66);

  --color-black: #000;
  --color-white: #fff;

  // Text and titles
  --color-text: #222;
  --color-text-pale: #999;

  --color-title: #303449;

  // Background
  --color-background: #F3F3F3;
  --color-grid-hover-background: #e6f3f9;
  --color-danger-background: #FFF7F7;

  // Modals
  --color-modal-title: #2D6A65;
  --color-modal-text: #73A698;

  // Border
  --color-border: #CCC;
  --color-border-darker: #eee;
  --color-error-border: #E43131;
  --border-radius: 8px;
  --card-border-radius-sm: 24px;
  --card-border-radius-md: 24px;

  // Form
  --color-form-checkbox: #d5d5d5;

  // ------------------------------------------------------------
  // Fixed Height
  // ------------------------------------------------------------
  --header-height: 55px;
  --button-square-size: 32px;

  // ------------------------------------------------------------
  // Animation
  // ------------------------------------------------------------
  --speed-default: 0.3s;

  // ------------------------------------------------------------
  // Padding - Margin
  // ------------------------------------------------------------
  --spacing-sm: 15px;
  --spacing-lg: 22px;
  --spacing-xl: 28px;
  --padding-input: 12px;

  // ------------------------------------------------------------
  // Main Menu
  // ------------------------------------------------------------
  --main-menu-width-opened: 262px;
  --main-menu-width-closed: 60px;
}

// ------------------------------------------------------------
// Boostrap Overwrite
// ------------------------------------------------------------
/* Change theme-colors */
$theme-colors: (
  "primary": #1AA8F8,
  "secondary": #E9F6FF,
  "success": #24B200,
  "info": #64c3d2,
  "warning" : #f5e74a,
  "danger": #E43131
);

/* Basic Colors */
$blue: #1AA8F8;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #24B200;
$teal: #0a0b0b;
$cyan: #0dcaf0;
$white: #fff;

/* Switch Overwrite */
$form-switch-color: #fff;
$form-switch-width: 54px;
$form-switch-padding-start: 0;
$form-switch-focus-color: #fff;
$form-switch-checked-color: #fff;
