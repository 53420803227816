/**
* Components : Dropdown
*/

.dropdown-menu--tabs {
  padding: 20px;
  line-height: 1.4;
  border: 1px solid var(--color-border);
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 4px 0 rgba(000, 000, 000, 0.2);

  .text {
    color: var(--color-primary);
  }
}

.dropdown-menu {
  display: block;
  //inset: 100% 0 auto auto !important;
  min-width: 150px;
  margin: {
    top: 5px;
  }
  padding: 20px;
  line-height: 1.4;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  box-shadow: 0 3px 4px 0 rgba(000, 000, 000, 0.2);

  li {
    + li {
      margin: {
        top: 10px;
      }
    }
  }

  button, a {
    display: flex;
    align-items: center;
    width: 100%;
    transition: all var(--speed-default) ease;

    color: var(--color-primary);
    font-size: 0.85rem;

    &:focus,
    &:hover,
    &:active {
      color: var(--color-primary-dark);

      svg {
        path {
          fill: var(--color-primary-dark);
        }
      }
    }
  }

  span.text {
    text-align: left;
  }

  svg {
    margin: {
      right: 10px;
    }

    path {
      fill: var(--color-primary);
    }
  }
}
