/**
* Generic : Alignment
*/

// Alignment - Works only if element is inside diplay: flex; parent
.align-right {
  margin: {
    left: auto !important;
  }
}

.align-left {
  margin: {
    right: auto !important;
  }
}

.align-center {
  margin: {
    left: auto !important;
    right: auto !important;
  }
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}
